@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700;800&display=swap");

body {
  @apply bg-th-black p-4 md:p-8 lg:p-16;
  margin: 0;
  font-family: "Manrope", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.p-default {
  @apply px-4 md:px-8 lg:px-16;
}

h1 {
  @apply text-4xl md:text-6xl lg:text-7xl xl:text-8xl font-extrabold leading-tight;
}

h2 {
  @apply text-3xl md:text-4xl lg:text-5xl font-extrabold leading-tight;
}

h3 {
  @apply text-2xl md:text-3xl lg:text-4xl font-bold leading-tight;
}

p {
  @apply text-sm font-normal;
}

.site-btn {
  @apply flex justify-center items-center max-w-[260px] w-full border-2 border-black py-4 px-12 bg-th-primary rounded-[20px] text-white text-base lg:text-xl font-bold uppercase;
}

.site-btn:hover {
  @apply bg-th-black;
}
.navlink{
  @apply text-3xl font-bold;
}

.navlink:hover {
  @apply text-th-primary;
}

.userform input {
  @apply border border-th-black rounded-[20px] bg-white text-th-black text-sm p-4;
}

.userform input:placeholder {
  @apply text-th-black/50;
}

.error-span {
  @apply text-sm font-bold text-red-500;
}

.success-span {
  @apply text-sm font-bold text-green-500;
}

.footer-nav a {
  @apply text-th-black text-lg font-bold;
}

.footer-nav a:hover {
  @apply text-th-primary;
}